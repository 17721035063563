<template>
    <v-card>
        <v-card-title>
            <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="item.label"
                            :label="trans('fields.common.label')"
                            :error-messages="errors.label"
                            @input="clearError('label')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="item.description"
                            :label="trans('fields.promoCode.description')"
                            :error-messages="errors.description"
                            @input="clearError('description')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="item.slug"
                            :label="trans('fields.promoCode.slug')"
                            :error-messages="errors.slug"
                            @input="clearError('slug')"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-select
                            v-if="options"
                            :items="options.promoCodeItemStatuses"
                            :label="trans('fields.promoCodeItem.status')"
                            v-model="item.status"
                            :error-messages="errors.status"
                            @input="clearError('status')"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-dialog
                            ref="validFromDialog"
                            v-model="validFromModal"
                            :return-value.sync="item.valid_from"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="item.valid_from"
                                    :label="trans('fields.promoCode.validFrom')"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="item.valid_from"
                                scrollable
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="validFromModal = false"
                                >
                                    {{ trans('buttons.common.cancel') }}
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.validFromDialog.save(item.valid_from)"
                                >
                                    {{ trans('buttons.common.apply') }}
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-dialog
                            ref="validToDialog"
                            v-model="validToModal"
                            :return-value.sync="item.valid_to"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="item.valid_to"
                                    :label="trans('fields.promoCode.validTo')"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="item.valid_to"
                                scrollable
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="validToModal = false"
                                >
                                    {{ trans('buttons.common.cancel') }}
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.validToDialog.save(item.valid_to)"
                                >
                                    {{ trans('buttons.common.apply') }}
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="item.period"
                            :label="trans('fields.promoCode.period')"
                            :error-messages="errors.period"
                            @input="clearError('period')"
                        >
                            <template slot="append">
                                {{trans('period.month.five')}}
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="item.discount"
                            :label="trans('fields.promoCode.discount')"
                            :error-messages="errors.discount"
                            @input="clearError('discount')"
                        >
                            <template slot="append">%</template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="$emit('close')">{{ trans('buttons.common.cancel') }}</v-btn>
            <v-btn v-if="itemId" color="blue darken-1" text @click="save">{{ trans('buttons.common.save') }}</v-btn>
            <v-btn v-else color="blue darken-1" text @click="save">{{ trans('buttons.common.create') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";
    export default {
        name: "PromoCodeItemForm",
        mixins: [formValidate],
        components: {
        },
        props: {
            itemId: {
                type: [Number, undefined],
            },
            promo_code_id: {
                type: [Number, undefined],
            }
        },
        data(){
            return {
                validFromModal: false,
                validToModal: false,
                item: {
                    id: null,
                    slug: null,
                    label: null,
                    description: null,
                    valid_from: null,
                    valid_to: null,
                    period: null,
                    discount: null,
                    status: null,
                    promo_code_id: this.promo_code_id,
                },
                options: {},
            }
        },
        created() {
            this.load();
        },
        methods: {
            load(){
                this.loadOptions();
                if (this.itemId){
                    axios.get(this.route('admin.promoCodes.items.show', this.itemId)).then( response => {
                        this.item = response.data;
                        this.item.valid_from = this.$options.filters.dateFormat(this.item.valid_from, 'YYYY-MM-DD');
                        this.item.valid_to = this.$options.filters.dateFormat(this.item.valid_to, 'YYYY-MM-DD');
                    }).catch( err => {
                        console.log(err);
                    })
                }
            },
            loadOptions(){
                axios.post(this.route('admin.options'), {
                    promoCodeItemStatuses: true,
                }).then( response => {
                    this.options = response.data;
                })
            },
            save() {
                if (!this.item.id) {
                    axios.post(this.route('admin.promoCodes.items.store'), this.item).then((response) => {
                        console.log('save success');
                        this.$emit('saved', response.data.id);
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                    })
                } else {
                    axios.post(this.route('admin.promoCodes.items.update', this.itemId), this.item).then((response) => {
                        console.log('save success');
                        this.$emit('saved', response.data.id);
                    }).catch( (err) => {
                        this.errors = err.response.data.errors;
                        console.log(err.response.data);
                    })
                }
            },
        },
        computed: {
            formTitle() {
                return !this.itemId
                    ? this.trans('pages.promoCodeItem.newDialogTitle')
                    : this.trans('pages.promoCodeItem.editDialogTitle');
            },
        }
    }
</script>
